import * as React from "react"
import Layout from "../components/layout"
import ScalingBanner from "../components/scaling-banner/scaling-banner"
import SubTitle from "../components/scaling-banner/sub-title"
import Seo from "../components/seo"
import Quote from "../components/quote/quote"
import QuoteSlider from "../components/quote-slider/quote-slider"
import TeamSlider from "../components/team/team-slider"
import Title from "../components/title/title"
import ContentContainer from "../components/content-container/content-container"
import SmallSpacer from "../components/spacer/small-spacer"
import Spacer from "../components/spacer/spacer"
import { graphql } from "gatsby"
import Video from "../components/video/video"
import SquareScalingBanner from "../components/square-scaling-banner/square-scaling-banner"
import OperationsList from "../components/operations/operations-list"
import NewsFeatured from "../components/news/news-featured"
import FadeInView from "../components/fade-inview/fade-inview"
import Loader from "../components/loader/loader"
import ArrowLink from "../components/arrow-link/arrow-link"
import Careers from "../components/careers"


function Grow() {
  return (
    <svg width="990" height="339" viewBox="0 0 990 339" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M104.175 222.924H119.233V287.459C119.233 290.471 117.512 291.762 114.931 291.762H93.4188C90.8374 291.762 89.1164 290.471 89.1164 287.459V80.9452C89.1164 77.9335 90.8374 76.6428 93.4188 76.6428H114.931C117.512 76.6428 119.233 77.9335 119.233 80.9452V136.876H164.408V80.9452C164.408 52.9797 142.896 31.4678 114.931 31.4678H93.4188C65.4534 31.4678 43.9415 52.9797 43.9415 80.9452V287.459C43.9415 317.576 63.3022 336.937 93.4188 336.937H114.931C145.047 336.937 164.408 317.576 164.408 287.459V177.749H104.175V222.924Z" fill="#EEEAD9" />
      <path d="M446.343 72.8758C446.343 48.5873 427.659 29.9038 403.371 29.9038H341.715V291.473H380.951V192.45H391.413L408.976 291.473H451.948L426.538 186.845C438.87 179.745 446.343 166.667 446.343 149.478V72.8758ZM403.371 69.1391C405.613 69.1391 407.108 70.2601 407.108 72.8758V149.478C407.108 152.094 405.613 153.215 403.371 153.215H380.951V69.1391H403.371Z" fill="#EEEAD9" />
      <path d="M660.603 256.045C682.847 256.045 697.146 241.746 697.146 219.502V66.9742C697.146 46.3194 681.258 30.4311 660.603 30.4311H644.715C624.06 30.4311 608.172 46.3194 608.172 66.9742V219.502C608.172 241.746 622.471 256.045 644.715 256.045H660.603ZM644.715 222.68C642.808 222.68 641.537 221.727 641.537 219.502V66.9742C641.537 64.7499 642.808 63.7966 644.715 63.7966H660.603C662.51 63.7966 663.781 64.7499 663.781 66.9742V219.502C663.781 221.727 662.51 222.68 660.603 222.68H644.715Z" fill="#EEEAD9" />
      <path d="M891.414 147.698L880.238 31.2571H851.647L871.661 213.197H906.749L917.146 124.566L927.542 213.197H962.631L982.644 31.2571H954.053L942.877 147.698L930.921 31.2571H903.37L891.414 147.698Z" fill="#EEEAD9" />

    </svg>
  )
}

const IndexPage = ({ data: { team, operation, featuredNews, home }, location }) => {

  const quotes = [
    '“Smithfield are regarded as experts in their field. Very strong reputation in the industry.”',
    '“Smithfield don’t just feed our cattle. They support us.”',
    '“I’m really proud to have my cattle in a Smithfield feedlot.”',
    '“They have to be very good at their jobs and they have to ‘turn-up’ every day.  Smithfield do that.”'
  ]



  return (
    <Layout>

      <Seo title="Home" />
      <Loader internal={location?.state?.internal} />

      <ScalingBanner svg={<Grow />} image={home.heroImage} title='Grow' video={home.videoLink}>
        <div className="flex flex-end">
          <SubTitle titles={['Confidently', 'Sustainably', 'Reliably', 'Proudly']} />
        </div>
      </ScalingBanner>


      <Quote max="max-800" quote={home.quote} >
        <ArrowLink name='Read More' link='/about' />
      </Quote>


      <ContentContainer>
        <Title link='/operations' className='grid-column-3' arrowClass='flex flex-end'>
          <div />
          <div className='flex-grow-1 flex ju-c m-flex-start'>
            <h5>Our Operations</h5>
          </div>
        </Title>
        <SmallSpacer />
      </ContentContainer>

      <OperationsList operations={operation.operations} />

      <Spacer />


      <div className="text-center flex flex-c ju-c">
        <h5>{home.videoTitle}</h5>
      </div>
      <SmallSpacer />



      <ContentContainer>
        <Video preview={home.previewVideoUrl} src={home.videoUrl} />
      </ContentContainer>



      <QuoteSlider quotes={quotes} />



      <ContentContainer>
        <Title link='/team'>
          <div className="flex flex-c ju-c" >
            <h5>meet the team</h5>
          </div>
          <div className="flex-grow-1" />
        </Title>
        <SmallSpacer />
      </ContentContainer>


      <TeamSlider team={team.members} />

      <Spacer />

      <Careers title={home.careersTitle} image={home.careersImage} />

      <SmallSpacer />
      <SmallSpacer/>
      <ContentContainer>
        <Title link='/news/'>
          <div className="flex flex-c ju-c">
            <h5>latest news</h5>
          </div>
          <div className="flex-grow-1" />
        </Title>
        <SmallSpacer />
      </ContentContainer>




      <SquareScalingBanner container='m-p0  m-r0 ' video={home.blogPost.featuredVideoUrl} image={home.blogPost.image} link={`/news/${home.blogPost.slug}`} wrapperClassName="al-i-start pl180 parent--hover--enabled">
        <NewsFeatured newsItem={home.blogPost} />
      </SquareScalingBanner>

      <Spacer />

    </Layout>)
}

export default IndexPage


export const query = graphql`
  query HomePage {
    home:datoCmsHomePage {
    quote
    videoTitle
    videoLink
    careersTitle
    videoUrl
    careersImage{
      gatsbyImageData
    }
    heroImage {
      gatsbyImageData
      url
    }
    previewVideoUrl
    blogPost {
      name
      category {
        name
      }
      featuredVideoUrl
      image {
        gatsbyImageData
      }
      slug
      meta {
        published:firstPublishedAt(formatString: "DD.MM.YYYY")
      }
    }
  }
    team:allDatoCmsTeamMember(sort: {fields: position},filter: {senior: {eq: true}}) {
      members:nodes{
        ...TeamThumbNail
      }
    }
    operation:allDatoCmsOperation(sort: { fields: name}){
      operations:nodes{
        ...OperationBanner
      }
    }
    featuredNews:datoCmsNews{
      ...NewsFeatured
    }
  }
`
