import React from 'react'
import ArrowLink from '../arrow-link/arrow-link'
import SmallSpacer from '../spacer/small-spacer'
import { graphql } from 'gatsby'


export default function NewsFeatured({ newsItem }) {
    return (
        <div className='flex  flex-c flex-start'>
            <div className='flex '>
                <h6 className='small-serif mr20 '>{newsItem.category.name}</h6>
                <h6 className='small-serif '>{newsItem.date ? newsItem.date : newsItem.meta.published}</h6>
            </div>
            <SmallSpacer />
            <div>
                <h3 className='h3 max-600 text-left m-h4'> {newsItem.name}</h3>
            </div>
            <SmallSpacer />
            <div className='pointer-none'>
                <ArrowLink name='Read More' hover={false} />
            </div>

        </div>
    )
}


export const query = graphql`
    fragment NewsFeatured on DatoCmsNews {
                    name
            slug
            date(formatString: "MM:DD:YYYY")
            featuredVideoUrl
            image {
                gatsbyImageData
            }
            category {
                name
            }
            meta {
                published:firstPublishedAt(formatString: "DD.MM.YYYY")
            }
    } 
`