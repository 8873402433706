import React, { useState } from 'react'
import { Autoplay } from 'swiper'
import { SwiperSlide } from 'swiper/react'
import BlockSlider from '../block-slider/block-slider'
import TeamThumbnail from './team-thumbnail'
import TeamSlideIn from './team-slide-in'


function TeamSlider({ team }) {

    const teamSliderOptions = {
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        slidesPerView: 'auto',
        spaceBetween: 20,

        allowTouchMove: false,

    }


    const [teamMember, setTeamMember] = useState(null)

    return (
        <>
            <TeamSlideIn close={() => setTeamMember(null)} team={teamMember} />
            <BlockSlider
                id='team-slider'
                modules={[Autoplay]}
                className='team--slider no-grid-gap  bg-bark pl30 pr30'
                options={teamSliderOptions}

            >
                {team.map((teamMember, index) => {
                    return (
                        <SwiperSlide key={index} className='max-500 flex m-mw400'>
                            <TeamThumbnail onClick={() => setTeamMember(teamMember)} className='pt125 ' teamMember={teamMember} />
                        </SwiperSlide>
                    )
                })}
            </BlockSlider>
        </>
    )
}

export default TeamSlider