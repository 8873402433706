import React from 'react'
import SmallSpacer from '../spacer/small-spacer'
import Spacer from '../spacer/spacer'
import { Swiper, SwiperSlide } from 'swiper/react';

import { Pagination } from 'swiper';
import ContentContainer from '../content-container/content-container';


function Bullet(swiper, current, total) {
   
    const pagination = Array(total).fill(0).map((_, index) => {
        return (
            `<svg class='bullet ${(current - 1 === index ? ' active' : '')}'  width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.05711 1.91062C7.49263 2.18286 7.49263 2.81714 7.05711 3.08937L4.36883 4.76975C4.14346 4.91062 3.85748 4.91064 3.63209 4.76979L0.943174 3.08941C0.507572 2.81719 0.507572 2.18281 0.943174 1.91059L3.63209 0.230213C3.85748 0.0893611 4.14346 0.0893764 4.36883 0.230252L7.05711 1.91062Z" fill="#2E2723" />
            </svg>`
        )
    })
    return pagination.join(' ')
}

function Slider({ quotes }) {
    return (
        <div className='flex ju-c flex-c al-i-c max-600 ma'>
            <Swiper modules={[Pagination]} className='max-600 grab  ' pagination={{ clickable: true, el: '.quote--slider', type: 'custom', renderCustom: Bullet }}>
                {quotes.map((quote,index) => (
                    <SwiperSlide key={index}>
                        <div className=' ma'>
                          
                            <p className='large-sentence'> {quote}</p>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <SmallSpacer />
            <SmallSpacer />
            <div className='quote--slider max-600 w100'></div>
        </div>
    )
}


export default function QuoteSlider({ quotes }) {
    return (
        <ContentContainer className='bg-bark'>
            <Spacer className='m-hide' />
            <Spacer />
            <Slider quotes={quotes} />
            <Spacer />
            <SmallSpacer />
        </ContentContainer>
    )
}
