import React, { Children, memo, useMemo } from 'react'
import { Swiper } from 'swiper/react';

import { Pagination, Navigation } from 'swiper';
import SmallSpacer from '../spacer/small-spacer';
import LeftArrow from '../../icons/left-arrow';
import RightArrow from '../../icons/right-arrow';
import ContentContainer from '../content-container/content-container';
import useIndex from '../../utils/useIndex';


function BlockSlider({ className, children, options, modules = [], id = Math.random() }) {



    const { setCurrent, current } = useIndex(Children.count(children))

    const swiperOptions = useMemo(() => ({

        modules: [Pagination, Navigation, ...modules],
        pagination: { clickable: true, type: 'progressbar', el: `div[data-progress='${id}']` },
        onSlideChange: (swiper) => { setCurrent(swiper.progress); },
        navigation: { nextEl: `div[data-next='${id}']`, prevEl: `div[data-prev='${id}']`, },
        slidesPerView: 'auto',

    }), [])

    const classNames = useMemo(() => {
        return [className, (modules.some(module => module.name === 'AutoPlay') ? '' : 'grab')].join(' ')
    }, [modules])




    return (
        <>
            <Slider classNames={classNames} swiperOptions={swiperOptions} options={options} >
                {children}
            </Slider>

            <SmallSpacer />
            <SmallSpacer />
            <ContentContainer>
                <div className='block--controls'>
                    <span className='caption orange'>{current}</span>
                    <div className='flex ju-c al-i-c' >
                        <div data-progress={id} className='block--slider flex flex-grow-1 ' />
                    </div>
                    <div className='flex flex-end'>
                        <div data-prev={id} className='swiper-button-prev mr10'>
                            <RightArrow />
                        </div>
                        <div data-next={id} className='swiper-button-next'>
                            <LeftArrow />
                        </div>
                    </div>
                </div>
            </ContentContainer>

            <SmallSpacer />

        </>
    )
}

const Slider = memo(({ classNames, children, swiperOptions, options }) => {
    return (
        <Swiper className={classNames} {...swiperOptions} {...options} >
            {children}
        </Swiper>
    )
})

export default BlockSlider
