import React from 'react'
import ArrowLink from './arrow-link/arrow-link'
import { GatsbyImage } from 'gatsby-plugin-image'

export default function Careers({ title, image, }) {
  return (
    <>
      <div className="pl30 pr30">
        <div className="bt1"></div>
      </div>
      <div className='image--content p30 m-p15 flex m-wrap m-col-reverse m-mha'>
        <div className="w-50 m-100 flex flex-wrap">
          <div className="max-450 pr30 mb150 m-mb20">
            <p className="h3 m0 mb40 m-mb20">{title}</p>
          </div>
          <div className="w-100 mta pr30">
            <ArrowLink link={'/contact'} name='Contact us' />
          </div>
        </div>
        <div className="w-50 r6 m-100 bg-leather pos-rel m-ratio-2-1 m-mb20 m-br5 overflow-hidden">
          <GatsbyImage alt='' image={image?.gatsbyImageData} className='bg-image' />
        </div>
      </div>
      <div className="pl30 pr30 mb20">
        <div className="bt1"></div>
      </div>
    </>
  )
}

