import React from 'react'

export default function PlayIcon({fill = "#2E2723" }) {
    return (
        <svg className='play' width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.4 4.15359C8.66667 4.30755 8.66667 4.69245 8.4 4.84641L1.35 8.91673C1.08333 9.07069 0.75 8.87824 0.75 8.57032L0.75 0.429681C0.75 0.121761 1.08333 -0.07069 1.35 0.0832701L8.4 4.15359Z" fill={fill}/>
        </svg>
        
    )
}
