import React, { useState } from 'react'
import Play from './play'
import { motion, AnimatePresence } from 'framer-motion'
import ReactPlayer from 'react-player/file'

export default function Video({ src, preview }) {

    const [isPreview, setPreview] = useState(true)

    return (
        <div className='w100 r6 '>
            <div className='bg-wrapper pt50  r6 ma'>
                <div className='bg-image z-1  r6 flex al-i-c ju-c'>
                    <AnimatePresence exitBeforeEnter>
                        {isPreview ? (
                            <motion.button initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} onClick={() => setPreview(false)} key="preview">
                                <ReactPlayer loop={true} muted={true} playsinline playing={true} url={preview} height="100%" width="100%" />
                            </motion.button>
                        ) : (
                            <motion.div className='w100 h100' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} key="video" >
                                <ReactPlayer controls={true} loop={true} muted={true} playsinline playing={true} url={src} height="100%" width="100%" />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
                <AnimatePresence exitBeforeEnter>
                    {isPreview && (<motion.div key='play' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='bg-image z-2 ma pointer-none'>
                        <Play active={true} />
                    </motion.div>)}
                </AnimatePresence>
            </div>
        </div>
    )
}

