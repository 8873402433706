import React from 'react'

import PlayIcon from '../../icons/play'
export default function Play({onClick,active}) {
    return (
        <button className='flex al-i-c ju-c h100 play--wrapper' onClick={onClick}>
            <div className='flex'>
                <div className={'play--icon flex al-i-c ju-c ' + (active ? '' : 'playing')} >
                    <PlayIcon/>
               
                </div>
            </div>
        </button>
    )
}
