import { gsap } from "gsap";
import React, { useEffect } from 'react';

function countDate(id, start, end, duration) {
    var range = end - start;
    var current = start;
    var increment = end > start ? 1 : -1;
    var stepTime = Math.abs(Math.floor(duration / range));
    var obj = document.getElementById(id);
    var timer = setInterval(function () {
        current += increment;
        obj.innerHTML = current;
        if (current == end) {
            clearInterval(timer);
        }
    }, stepTime);
}

export default function Loader({ internal }) {
    useEffect(() => {

        if (!internal) {
            var tl = gsap.timeline({ paused: true });
            tl.to("html", { height: '100vh', overflow: 'hidden' })
            tl.to("body", { height: '100vh', overflow: 'hidden' })
            tl.to((".preloader .animation--wrapper"), { opacity: 1, delay: 1 });
            tl.from((".preloader"), { delay: 0, onComplete: function () { countDate("year", 1928, new Date().getFullYear(), 1500); document.querySelector('.preloader').classList.add('loaded') } });
            tl.to((".preloader .left"), { width: '30vw', duration: 1, ease: "power2.inOut", delay: 2 });
            tl.to((".preloader .date--counter"), { y: '100%', ease: "power2.inOut", duration: .5 }, '-=1');
            tl.to((".preloader .animation--wrapper .line"), { opacity: 0, duration: 0.5 }, '-=1');
            tl.to((".preloader .left, .preloader .right"), { width: '0vw', ease: "power2.inOut", duration: 1, delay: .5 }, '-=.25');
            tl.to((".preloader .top, .preloader .bottom"), { height: '0vh', ease: "power2.inOut", duration: 1, onComplete: function () { document.querySelector('.preloader').style.display = 'none'; } }, '-=1');
            tl.from(('.scaling--banner'), { opacity: 0, duration: 1 }, '-=.25');
            tl.to("html", { height: 'unset', overflow: 'unset' }, '-=1')
            tl.to("body", { height: 'unset', overflow: 'unset' }, '-=1')

            tl.play();
        } else {
            gsap.from(('.scaling--banner'), { opacity: 0, duration: 1 });
            gsap.to('.preloader', { display: 'none' })
            gsap.to('.hero--banner', { opacity: 0 })
            gsap.to('.hero--banner', { opacity: 1, duration: 1, ease: 'ease' })
        }
    }, [])

    return (
        <div className={'preloader ' + (internal ? 'non-active' : '')}>
            <div className="animation--wrapper">
                <div className="year--wrapper">
                    <p id="year" className="date--counter m0 h5">1928</p>
                </div>
                <div className="line"></div>
                <div className="line bg"></div>
            </div>
            <div className="background">
                <div className="side left"></div>
                <div className="side top"></div>
                <div className="side right"></div>
                <div className="side bottom"></div>
            </div>
        </div>
    )
}
